angular.module('psModule').controller('HomeCtrl', function($scope, $rootScope, $http, $uibModal, $resource, meeting, benefitService, DocumentStatefulService) {
    var OldAgeCategory = 'Alderspension',
        SicknessCategory = 'Sjukdom';
    $scope.home = {};
    $scope.home.meeting = meeting.data || undefined;
    $scope.user = $rootScope.user;
    $scope.useCapitex = capitexIsActive();

    var customerOfferResource = $resource('/ps/api/customer-offers');
    if ($scope.user.fullAccess){
        customerOfferResource.query().$promise.then(data => {
            $scope.customerOffers = data;
        })
    } else {
        console.log("Skippar erbjudande pga. begränsad PS-åtkomst.");
    }
    function capitexIsActive() {
        return $rootScope.config && $rootScope.config.toggles && $rootScope.config.toggles.USE_CAPITEX === true;
    }

    function calculateOldAgePension() {
        $scope.home.oldAgePension = 0;

        var subCategory = benefitService.getSubCategory(OldAgeCategory, 'per_man_tax');
        subCategory.then(function(sc) {
            $scope.home.oldAgePension = benefitService.calculateTotalCompensation(sc.forsakringsbeloppList);
        });
    }

    function calculateSickness() {
        $scope.home.sickness = 0;

        var subCategory = benefitService.getSubCategory(SicknessCategory, 'FROM_MONTH_3');
        subCategory.then(function(sc) {
            $scope.home.sickness = benefitService.calculateTotalCompensation(sc.forsakringsbeloppList);
        });
    }

    function calculateDurable() {
        $scope.home.durable = 0;

        var subCategory = benefitService.getSubCategory(SicknessCategory, 'LIFELONG');
        subCategory.then(function(sc) {
            $scope.home.durable = benefitService.calculateTotalCompensation(sc.forsakringsbeloppList);
        });
    }

    function calculateUnreadDocuments() {
        $scope.unreadMessages = 0;
        DocumentStatefulService.getYourDocuments().then(
            function (data) {
                $scope.unreadMessages = data.unreadDocuments.length;
            }
        );
    }

    function init() {
        if ($rootScope.user.fullAccess) {
            benefitService.getFbesked().then(function (fbesked) {
                $scope.hasSalary = !!fbesked.assumptions.dateradArslon;
            });
            calculateOldAgePension();
            calculateSickness();
            calculateDurable();
        } else {
            calculateUnreadDocuments();
        }
    }

    init();

});
